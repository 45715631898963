import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import { Box, Heading, Button } from 'theme-ui'
import { Link } from 'gatsby'
import { FaLuggageCart } from 'react-icons/fa'
import { FaPlaneSlash } from 'react-icons/fa'
import { FaUsersSlash } from 'react-icons/fa'
import {FaHourglassHalf } from 'react-icons/fa'




const Sezione11 = () => (
  <>
  <HeroBoxed.Wrapper>


     <div className="boxsezione4"> 
     <div style={{width:"100%", textAlign:"center"}}>
     <Heading variant='h2'>
      
      
     I Servizi di EURORIMBORSO® <br></br>
     <h5>Ci occupiamo di richiedere il risarcimento per questi e altri disagi</h5>
     </Heading>
</div>

     <div className="boxelementisezione4">
     <div className="">
      </div>
     <div style={{textAlign: "center", fontSize: "4em", color:"#667eea"}}>
     <FaHourglassHalf /><br></br>
     </div>     
    
      <div style={{textAlign: "center"}}>
      <Heading variant='h3'>
      
      Volo in Ritardo
      
    </Heading>
    </div>
    <div style={{textAlign: "center"}}>
    
    <b>Ritardo</b> superiore alle 3 ore?<b> Ti spetta un risarcimento fino a 600€ </b>in tempi brevi e senza spese da parte tua.    
    </div>
     </div>
     <div className="boxelementisezione4">
     <div className="">
    </div>
    <div style={{textAlign: "center", fontSize: "4em", color:"#667eea"}}>
     <FaPlaneSlash/><br></br>
     </div>  
      <div style={{textAlign: "center"}}>
      <Heading variant='h3'>
Volo Cancellato
    </Heading>
    </div>
    <div style={{textAlign: "center"}}>
    Volo <b>Cancellato</b>? Avvia subito la pratica di risarcimento. Hai diritto al rimborso senza nessuna spesa. 
    </div>
     </div>
     <div className="boxelementisezione4">
     <div className="">

</div>
<div style={{textAlign: "center", fontSize: "4em", color:"#667eea"}}>
     <FaUsersSlash /><br></br>
     </div>  
      <div style={{textAlign: "center"}}>
      <Heading variant='h3'>
Overbooking
    </Heading>
    </div>
    <div style={{textAlign: "center"}}>
    Ti hanno negato l'imbarco per <b>overbooking</b>? ci occuperemo di richiedere il tuo risarcimento!. 
    </div>
     </div>
     <div className="boxelementisezione4">
     <div className="">

</div>
<div style={{textAlign: "center", fontSize: "4em", color:"#667eea"}}>
     <FaLuggageCart /><br></br>
     </div>  
      <div style={{textAlign: "center"}}>
      <Heading variant='h3'>Bagaglio Smarrito o Danneggiato</Heading>
    </div>
    <div style={{textAlign: "center"}}>
    <b>Bagaglio Smarrito, Danneggiato o in Ritardo? </b>Chiedi subito il risarcimento del danno
    </div>
     </div>     
     <div style={{width: "100%", textAlign: "center", marginTop: "20px"}}>
  <Button as={Link} to='#ContactForm'>
  Scopri quanto ti spetta!
      </Button>
      </div>




    </div>
  
  </HeroBoxed.Wrapper>
  
  </>
)


const testimonialQuery = graphql`
  query TestimonialQuery {
    file(absolutePath: { regex: "/testimonial.(jpeg|jpg|gif|png)/" }) {
      publicURL
    }
  }
  `
export default Sezione11
